export enum Action {
  InvalidRequest = 'InvalidRequest',
  Unauthenticated = 'Unauthenticated',
  UnauthenticatedV2 = 'UnauthenticatedV2',
  UnauthenticatedSinglePageLogin = 'UnauthenticatedSinglePageLogin',
  UnauthenticatedSinglePageLoginV2 = 'UnauthenticatedSinglePageLoginV2',
  PasswordInputLoginHint = 'PasswordInputLoginHint',
  SocialNetworkAssociation = 'SocialNetworkAssociation',
  PasswordInput = 'PasswordInput',
  UserBlocked = 'UserBlocked',
  SignUp = 'SignUp',
  SignUpDigitalBirth = 'SignUpDigitalBirth',
  SignUpDigitalBirthInit = 'SignUpDigitalBirthInit',
  SignUpByInvitation = 'SignUpByInvitation',
  SignUpByInvitationInit = 'SignUpByInvitationInit',
  SignUpSendSms = 'SignUpSendSms',
  SignUpChangePhone = 'SignUpChangePhone',
  SignUpPhoneCodeValidation = 'SignUpPhoneCodeValidation',
  SignUpEmailValidated = 'SignUpEmailValidated',
  SignUpEmailValidation = 'SignUpEmailValidation',
  SignUpComplete = 'SignUpComplete',
  SignUpByInvitationComplete = 'SignUpByInvitationComplete',
  SignUpDigitalBirthComplete = 'SignUpDigitalBirthComplete',
  SignUpSocialNetwork = 'SignUpSocialNetwork',
  SignUpSocialNetworkComplete = 'SignUpSocialNetworkComplete',
  MandatoryEmailCodeValidation = 'MandatoryEmailCodeValidation',
  MandatoryChangeEmail = 'MandatoryChangeEmail',
  MandatoryEmailValidated = 'MandatoryEmailValidated',
  AssociationsShowDetailsPrimary = 'AssociationsShowDetailsPrimary',
  AssociationsShowDetailsSecondary = 'AssociationsShowDetailsSecondary',
  AssociationsDetailsMandatoryInfo = 'AssociationsDetailsMandatoryInfo',
  AssociationsIdentifyPrimary = 'AssociationsIdentifyPrimary',
  AssociationsNifIdentification = 'AssociationsNifIdentification',
  AssociationsNifIdentificationContactSelection = 'AssociationsNifIdentificationContactSelection',
  AssociationsNifIdentificationSecondValidationEmailCodeValidation = 'AssociationsNifIdentificationSecondValidationEmailCodeValidation',
  AssociationsNifIdentificationSecondValidationPhoneCodeValidation = 'AssociationsNifIdentificationSecondValidationPhoneCodeValidation',
  AssociationsPassportIdentification = 'AssociationsPassportIdentification',
  AssociationsPassportIdentificationContactSelection = 'AssociationsPassportIdentificationContactSelection',
  AssociationsPassportIdentificationSecondValidationEmailCodeValidation = 'AssociationsPassportIdentificationSecondValidationEmailCodeValidation',
  AssociationsPassportIdentificationSecondValidationPhoneCodeValidation = 'AssociationsPassportIdentificationSecondValidationPhoneCodeValidation',
  AssociationsEmailIdentification = 'AssociationsEmailIdentification',
  AssociationsEmailIdentificationCodeValidation = 'AssociationsEmailIdentificationCodeValidation',
  AssociationsPhoneIdentification = 'AssociationsPhoneIdentification',
  AssociationsPhoneIdentificationCodeValidation = 'AssociationsPhoneIdentificationCodeValidation',
  ApplicationNotAuthorized = 'ApplicationNotAuthorized',
  ApplicationNotAuthorizedByAdmin = 'ApplicationNotAuthorizedByAdmin',
  ApplicationPermissionRequested = 'ApplicationPermissionRequested',
  UnauthorizedKid = 'UnauthorizedKid',
  TermsAndConditions = 'TermsAndConditions',
  Consents = 'Consents',
  PrivacyPolicies = 'PrivacyPolicies',
  AccountSelection = 'AccountSelection',
  Protection = 'Protection',
  ProtectionEmailCodeValidation = 'ProtectionEmailCodeValidation',
  ProtectionPhoneSendCode = 'ProtectionPhoneSendCode',
  ProtectionPhoneCodeValidation = 'ProtectionPhoneCodeValidation',
  ProtectionComplete = 'ProtectionComplete',
  PasswordRecovery = 'PasswordRecovery',
  PasswordRecoveryPending = 'PasswordRecoveryPending',
  PasswordRecoveryMandatory = 'PasswordRecoveryMandatory',
  PasswordRecoveryPhoneCodeValidation = 'PasswordRecoveryPhoneCodeValidation',
  PasswordRecoveryEmailCodeValidation = 'PasswordRecoveryEmailCodeValidation',
  PasswordRecoveryContactSelection = 'PasswordRecoveryContactSelection',
  PasswordRecoveryNewPasswordDefinition = 'PasswordRecoveryNewPasswordDefinition',
  PasswordRecoveryComplete = 'PasswordRecoveryComplete',
  SocialNetworkAssociationSingleStep = 'SocialNetworkAssociationSingleStep',
  SocialNetworkAssociationComplete = 'SocialNetworkAssociationComplete',
  // ? 2FA
  TwoFactorAuthCodeValidation = 'TwoFactorAuthCodeValidation',
  TwoFactorAuthCodeValidationEmail = 'TwoFactorAuthCodeValidationEmail',
  TwoFactorAuthFallback = 'TwoFactorAuthFallback',

  // ? 2FA Promotion
  TwoFactorAuthCodePromotion = 'TwoFactorAuthCodePromotion',
  TwoFactorAuthCodeValidationPromotion = 'TwoFactorAuthCodeValidationPromotion',
  TwoFactorAuthCodeValidationEmailPromotion = 'TwoFactorAuthCodeValidationEmailPromotion',

  RollbackUserContacts = 'RollbackUserContacts',

  RedirectTo = 'RedirectTo',
  LoggedOut = 'LoggedOut',
  KidsProfileSelection = 'KidsProfileSelection',
  KidsProfileCreation = 'KidsProfileCreation',
  DeviceCodesUserSelection = 'DeviceCodesUserSelection',
  DeviceCodesComplete = 'DeviceCodesComplete',

  GenericCountryCodeConfirmation = 'GenericCountryCodeConfirmation',
  InvalidTokenError = 'InvalidTokenError',
  ForbidenTokenError = 'ForbidenTokenError',
  UnknownError = 'UnknownError',

  SignUpPasswordInput = 'SignUpPasswordInput',

  SnackBarErrorAction = 'SnackBarErrorAction',
}
