import { FC } from 'react';
import {
  htmlClass,
  useI18n,
  WooIconArrowLeft,
} from '@nosinovacao/nosid-mfe-common';
import { ReturnButtonSkin } from '../backButton.props';

export const BackButtonWoo: FC<ReturnButtonSkin> = ({
  display,
  className,
  goBack,
}) => {
  const { t } = useI18n();
  if (!display) return <></>;
  return (
    <div
      className={htmlClass(
        'flex items-center gap-1 py-3 font-WooMedium font-semibold',
        className ?? '',
      )}
      onClick={() => {
        goBack();
      }}>
      <WooIconArrowLeft width={24} height={24} className={className ?? ''} />
      {t('Common.Translation.Back')}
    </div>
  );
};
