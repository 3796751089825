import {
  ActionType,
  ModuleName,
  Screen,
  TemplatesService,
  ValidatorCode,
  useBaseNosidStoreState,
  useForm,
  useI18n,
  useSetNavigationData,
} from '@nosinovacao/nosid-mfe-common';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppContext } from '@/context';
import { useValidActionGuard } from '@/guards';
import { useCreateLogEvent, useGoBack, useSignInCaptchaV2 } from '@/hooks';
import { AccountCredentials, Action } from '@/models';
import { useNavigation } from '@/navigation';
import { TwoFactorAuthenticationCodeInputDefault } from './default/code-input.default';
import {
  TwoFactorAuthenticationCodeFrom,
  TwoFactorAuthenticationCodeInputProps,
} from './code-input.props';
import { TWOFA_EMAIL_INPUT_KEY } from '@/constants';
import { useFallBack, useResendCode, useValidate2FaCode } from '@/api';
import { TwoFactorAuthenticationCodeInputWtf } from './wtf/code-input.wtf';
import { TwoFactorAuthenticationCodeInputWoo } from './woo/code-input.woo';

export function getSkin(
  template: TemplatesService,
  props: TwoFactorAuthenticationCodeInputProps,
) {
  switch (true) {
    case template.isWtfTemplate:
      return <TwoFactorAuthenticationCodeInputWtf {...props} />;
    case template.isWooTemplate:
      return <TwoFactorAuthenticationCodeInputWoo {...props} />;
    default:
      return <TwoFactorAuthenticationCodeInputDefault {...props} />;
  }
}

const actions = {
  Email: [
    Action.TwoFactorAuthCodeValidationEmail,
    Action.TwoFactorAuthCodeValidationEmailPromotion,
  ],
  Phone: [
    Action.TwoFactorAuthCodeValidation,
    Action.TwoFactorAuthCodeValidationPromotion,
  ],
};

export enum TwoFactorType {
  Phone = 'Phone',
  Email = 'Email',
}

const TwoFactorAuthenticationCodeInput: FC<{
  type: TwoFactorType;
}> = ({ type }) => {
  useValidActionGuard(...actions[type]);

  const translationKey = `${ModuleName.TwoFactorAuthentication}.${type}`;

  const { templates, logger, widgetsConfig, sessionStorageService, config } =
    useAppContext();

  const goBack = useGoBack();

  const createLogEvent = useCreateLogEvent();

  const [loadingIndex, setLoadingIndex] = useState(-1);

  const { i18n } = useI18n();

  useSetNavigationData(
    ModuleName.TwoFactorAuthentication,
    Screen.CodeInput,
    logger,
  );

  const { data, navigateToSpecificAction } = useNavigation<
    AccountCredentials & { PhoneIsAlternative?: boolean }
  >();

  const mustHideFallback = useMemo(
    () =>
      type === TwoFactorType.Email &&
      typeof data?.Data?.PhoneIsAlternative === 'boolean' &&
      !data.Data.PhoneIsAlternative,
    [data?.Data?.PhoneIsAlternative, type],
  );

  const promote = useMemo(
    () =>
      [
        Action.TwoFactorAuthCodeValidationPromotion,
        Action.TwoFactorAuthCodeValidationEmailPromotion,
      ].includes(data?.Action as Action),
    [data],
  );

  const contact = useMemo(
    () =>
      type === TwoFactorType.Email ? data?.Data?.Email : data?.Data?.Phone,
    [data?.Data?.Email, data?.Data?.Phone, type],
  );

  const { activeModule, activeScreen } = useBaseNosidStoreState().navigation;

  const { setToken, token, challenge } = useSignInCaptchaV2();

  const form = useForm<TwoFactorAuthenticationCodeFrom>(
    {
      Code: {
        value: '',
        validators: [ValidatorCode(widgetsConfig.ValidationCodeLength)],
      },
    },
    {
      activeModule,
      activeScreen,
      logger,
      parseErrors: true,
      translateKey: `${translationKey}.Errors`,
    },
  );

  useEffect(() => {
    if (data?.Error && form.dirty) {
      if (
        i18n.exists(`${translationKey}.Errors.${data.Error.Params?.[0].Code}`)
      ) {
        form.addError(
          'Code',
          `${translationKey}.Errors.${data.Error.Params?.[0].Code}`,
        );
      } else if (i18n.exists(`Errors.${data.Error.Params?.[0].Code}`)) {
        form.addError('Code', `Errors.${data.Error.Params?.[0].Code}.Message`);
      } else {
        form.addError('Code', 'Errors.DefaultError.Message');
      }
      setLoadingIndex(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, translationKey]);

  const currentSmsResendCount = useCallback((): number => {
    return +(sessionStorageService.getString(TWOFA_EMAIL_INPUT_KEY) ?? 0);
  }, [sessionStorageService]);

  const incrementSmsResend = () => {
    sessionStorageService.setString(
      TWOFA_EMAIL_INPUT_KEY,
      (currentSmsResendCount() + 1).toString(),
    );
  };

  const isResendEmail = useCallback(
    () =>
      currentSmsResendCount() > config.TwoFactorAuthFallbackCount &&
      (data?.Action === Action.TwoFactorAuthCodeValidation ||
        data?.Action === Action.TwoFactorAuthCodeValidationPromotion),
    [config.TwoFactorAuthFallbackCount, currentSmsResendCount, data?.Action],
  );

  const [canSendByEmail, setCanSendByEmail] = useState<boolean>(
    isResendEmail(),
  );

  const { mutate: resend, isLoading: loadingResend } = useResendCode((res) => {
    createLogEvent(res, ActionType.ResendCode, type);
    if (!isResendEmail()) {
      incrementSmsResend();
    }
    setCanSendByEmail(isResendEmail());
  });

  const { mutate: sendToFallback } = useFallBack((res) => {
    createLogEvent(res, ActionType.ResendCode, type);
    removeTwoFactorAttempt();
  });

  const removeTwoFactorAttempt = useCallback(() => {
    sessionStorageService.remove(TWOFA_EMAIL_INPUT_KEY);
  }, [sessionStorageService]);

  useEffect(() => {
    if (canSendByEmail && type !== TwoFactorType.Email) {
      sendToFallback();
    }
  }, [
    canSendByEmail,
    challenge,
    promote,
    sendToFallback,
    sessionStorageService,
    token,
    type,
  ]);

  const validateCode = useValidate2FaCode((res) => {
    if (!res.Error) {
      sessionStorageService.remove(TWOFA_EMAIL_INPUT_KEY);
    }
    createLogEvent(res, ActionType.Submit, type);
  });

  const props: TwoFactorAuthenticationCodeInputProps = {
    loadingIndex,
    setLoadingIndex,
    translationKey,
    form,
    formErrors: form.errors,
    type,
    showReturnButton: type === TwoFactorType.Phone && promote,
    onSubmitForm: async (res) => {
      if (challenge && !token) {
        return;
      }
      setLoadingIndex(2);

      await validateCode({
        code: res.Code,
        promote,
        tokenChallenge: challenge ? token : undefined,
      });
    },
    setRecaptchaToken: setToken,
    challenge,
    contact: contact ?? '',
    resend,
    loadingResend,
    goBack: () => {
      if (type === TwoFactorType.Phone) {
        goBack();
      }
      navigateToSpecificAction(
        promote
          ? Action.TwoFactorAuthCodeValidationPromotion
          : Action.TwoFactorAuthCodeValidation,
        true,
      );
      removeTwoFactorAttempt();
    },
    mustHideFallback,
  };

  return getSkin(templates, props);
};
export default TwoFactorAuthenticationCodeInput;
