import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { FooterSkinProps } from '../footer.props';
import { LogoutButton } from '@/components/logoutButton';

export const FooterWoo: FC<FooterSkinProps> = ({
  className,
  children,
  footerClassName,
  showLogoutButton,
}) => {
  const footerClass = htmlClass(
    'w-full pb-10',
    'tablet-sm:sticky flex flex-row gap-6',
    footerClassName ?? '',
  );

  return (
    <div className={footerClass}>
      <div
        className={htmlClass(
          'flex flex-col justify-center tablet-sm:flex-row gap-6 w-full tablet-sm:justify-start',
          className ?? '',
        )}>
        {showLogoutButton ? (
          <LogoutButton showLogoutButton={showLogoutButton}>
            {children}
          </LogoutButton>
        ) : (
          children
        )}
      </div>
    </div>
  );
};
