import {
  Button,
  Captcha,
  Link,
  IconLoader,
  NosTypography,
  useI18n,
  useInMediaQuery,
  Code,
} from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';

import { Container } from '@/components/container';
import { useAppContext } from '@/context';
import { TwoFactorAuthenticationCodeInputProps } from '../code-input.props';
import { SubContainer } from '@/components/subcontainer';
import { Header } from '@/components/header';
import { FooterByType } from '../footer-by-type';

export const TwoFactorAuthenticationCodeInputWoo: FC<
  TwoFactorAuthenticationCodeInputProps
> = ({
  challenge,
  form,
  formErrors,
  goBack,
  onSubmitForm,
  resend,
  setRecaptchaToken,
  translationKey,
  contact,
  loadingResend,
  loadingIndex,
  setLoadingIndex,
  type,
  mustHideFallback,
}) => {
  const { t } = useI18n();
  const { widgetsConfig, config } = useAppContext();
  const isTablet = useInMediaQuery('tablet-sm');
  const isDesktop = useInMediaQuery('desktop-sm');
  return (
    <Container onSubmit={form.handleSubmit(onSubmitForm)} el="form">
      <Header displayLogo={isDesktop}>
        {!isDesktop && (
          <NosTypography
            id="Title"
            variant="sectionTitle"
            className="!text-32 !text-grey-900 !leading-40">
            {t(`${translationKey}.Title`)}
          </NosTypography>
        )}
      </Header>
      <SubContainer>
        <div className="flex flex-col">
          {isDesktop && (
            <div className="flex flex-col justify-start items-start">
              <NosTypography
                id="Title"
                variant={isTablet ? 'sectionTitle' : 'headline'}
                className="!text-32 !text-grey-900 !leading-40">
                {t(`${translationKey}.Title`)}
              </NosTypography>
            </div>
          )}
          <NosTypography
            id="Subtitle"
            variant={isTablet ? 'body-Regular' : 'longText-Regular'}
            className={`${isDesktop && 'mt-10'}`}>
            {t(`${translationKey}.Subtitle`, { contact })}
          </NosTypography>
        </div>
        <div className="flex flex-col items-start justify-start">
          <Code
            id="2fa-code"
            autoComplete="one-time-code"
            length={widgetsConfig.ValidationCodeLength}
            setValue={(v) => {
              form.setValue('Code', v);
            }}
            value={form.value.Code}
            errors={formErrors.Code ? [t(formErrors.Code)] : []}
            className="mt-5"
          />
          <div className="my-12 w-full flex justify-center items-center tablet-sm:justify-start py-1 h-10">
            {loadingResend ? (
              <div>
                <IconLoader height={23} width={23} />
              </div>
            ) : (
              <Link action={resend} underline disabled={form.submitting}>
                {t(`${translationKey}.Resendcode`)}
              </Link>
            )}
          </div>
        </div>

        {challenge && (
          <Captcha
            className="mx-auto mt-6"
            onChange={setRecaptchaToken}
            siteKey={config.CloudflareRecaptchaChallengeSiteKey}
          />
        )}

        <FooterByType
          type={type}
          setLoadingIndex={setLoadingIndex}
          loadingIndex={loadingIndex}
          form={form}
          loadingResend={loadingResend}
          translationKey={translationKey}
          goBack={goBack}
          mustHideFallback={mustHideFallback}
          t={t}
          className=" mt-auto w-full flex !flex-col-reverse tablet-sm:!flex-row">
          <Button
            id="CodeInputButton"
            className=""
            type="submit"
            disabled={
              form.pristine ||
              form.submitting ||
              loadingResend ||
              loadingIndex >= 0
            }>
            {loadingIndex === 2 ? (
              <IconLoader height={22} width={22} />
            ) : (
              t(`${translationKey}.Button`)
            )}
          </Button>
        </FooterByType>
      </SubContainer>
    </Container>
  );
};
