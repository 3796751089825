import { htmlClass } from '@nosinovacao/nosid-mfe-common';
import { FC } from 'react';
import { HeaderProps } from '../header.props';
import { IconLogo } from '@/components/IconLogo';
import { useShowLogo } from '@/hooks/showLogo.hook';
import { BackButton } from '@/components/backButton';

export const HeaderWoo: FC<HeaderProps> = ({
  children,
  className,
  displayLogo,
  displayReturnButton,
}) => {
  const { showLogo } = useShowLogo(displayLogo);
  return (
    <div
      className={htmlClass(
        'px-4 m-h-[180px] py-6 pb-10 mb-10  w-full flex items-end  bg-wooGreenMint tablet-sm:pt-[102px] tablet-sm:pb-8 tablet-sm:px-[78px] desktop-sm:py-6 desktop-sm:px-[195px] desktop-sm:mb-[88px] desktop-st:mb-[194px]',
        className ?? '',
      )}>
      <div>
        {showLogo && (
          <div className="w-full flex">
            <IconLogo />
          </div>
        )}
        <div
          className={htmlClass(
            'flex flex-col mt-10 tablet-sm:mt-8 desktop-sm:mt-4',
            className ?? '',
          )}>
          {displayReturnButton?.display && (
            <BackButton {...displayReturnButton} />
          )}
        </div>
        <div
          className={htmlClass(
            'flex flex-col justify-start items-start gap-2 ',
          )}>
          <div className="flex flex-col justify-center items-center gap-4 ">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
