/* eslint-disable no-nested-ternary */
import { FC } from 'react';
import {
  Button,
  IconLoader,
  NosTypography,
  WooIconAlert,
  htmlClass,
  useI18n,
  useInMediaQuery,
} from '@nosinovacao/nosid-mfe-common';
import { ErrorSkinProps } from '../error.props';
import { Container } from '@/components/container';
import { Footer } from '@/components/footer';
import { SubContainer } from '@/components/subcontainer';
import { Header } from '@/components/header';

export const ErrorWoo: FC<ErrorSkinProps> = ({
  action,
  button,
  level,
  title,
  tooltip,
  onClick,
  shouldNotDisplayButton,
  loadingIndex,
  setLoadingIndex,
}) => {
  const { t } = useI18n();
  const isDesktop = useInMediaQuery('desktop-sm');

  return (
    <Container>
      <Header
        displayLogo={isDesktop}
        className={htmlClass(
          // eslint-disable-next-line no-nested-ternary
          !isDesktop ? '!bg-wooYellowCyber min-h-[46%]' : '',
        )}>
        {!isDesktop && <IconComponent level={level} />}
      </Header>
      <SubContainer>
        <div className="flex flex-col gap-10">
          {isDesktop && <IconComponent level={level} />}
          <div className={htmlClass('flex flex-col gap-5')}>
            <NosTypography variant="longText-Regular">
              {t(tooltip)}
            </NosTypography>
            {shouldNotDisplayButton && (
              <NosTypography variant="longText-Regular">
                {t(title)}
              </NosTypography>
            )}
          </div>
          {action && button && !shouldNotDisplayButton && (
            <Footer>
              <Button
                className="w-full"
                disabled={loadingIndex >= 0}
                onClick={onClick}>
                {loadingIndex === 2 ? (
                  <IconLoader height={22} width={22} />
                ) : (
                  t('Generic.Error.Woo.Button')
                )}
              </Button>
            </Footer>
          )}
        </div>
      </SubContainer>
    </Container>
  );
};

const IconComponent: FC<{ level: 'error' | 'warning' }> = ({ level }) => {
  const { t } = useI18n();

  const getIcon = () => {
    switch (level) {
      default:
        return (
          <WooIconAlert
            className="fill-black  desktop-sm:fill-wooFucsia"
            width={104}
            height={104}
          />
        );
    }
  };

  return (
    <div className="flex flex-col justify-start items-start gap-2">
      {getIcon()}
      <div className="flex flex-col justify-start items-start gap-4">
        <NosTypography
          variant="sectionTitle"
          className="!text-32 !text-grey-900 !leading-40">
          {t('Generic.Error.Woo.Title')}
        </NosTypography>
      </div>
    </div>
  );
};
