import {
  ErrorCodes,
  NOSIDError,
  useBaseNosidStoreState,
} from '@nosinovacao/nosid-mfe-common';
import { useMutation } from '@tanstack/react-query';
import Axios, { AxiosError, AxiosInstance } from 'axios';
import { useState } from 'react';
import {
  KEEP_SESSION_STORAGE_KEY,
  SESSION_TOKEN_STORAGE_KEY,
} from '@/constants';
import { useAppContext } from '@/context';
import { useNavigation } from '@/navigation';
import { Action, ResponseAction } from '@/models';

export function useNosidSignInMutation<TInput = void>(
  mutationFn: (axios: AxiosInstance, input: TInput) => Promise<ResponseAction>,
  options?: {
    onResponse?: (data: ResponseAction, input: TInput) => void;
    loader?: boolean;
    retry?:
      | number
      | ((
          failureCount: number,
          error: AxiosError<ResponseAction<unknown>, any>,
        ) => boolean);
  },
) {
  const { loader } = useBaseNosidStoreState();
  const {
    config,
    utilsService,
    deviceIdService,
    enterpriseContextService,
    sessionStorageService,
    localStorageService,
    templates,
  } = useAppContext();
  const [axios] = useState(() => {
    const instance = Axios.create({
      baseURL: config.APIConfiguration.AA.BaseUrl,
    });
    instance.interceptors.request.use(async (c) => {
      if (c.data) {
        c.data = utilsService.trimValues(c.data);
      }
      c.headers['x-device-id'] = await deviceIdService.deviceId;
      c.headers['x-enterprise-name'] =
        templates.clientTemplate.EnterpriseContext ??
        enterpriseContextService.enterpriseContext;
      c.headers['x-clear-text-password'] = 'true';
      return c;
    });
    return instance;
  });

  const { navigateToError } = useNavigation();

  const handle4xxError = (err: AxiosError<ResponseAction, any>) => {
    if (Math.floor((err.response?.status ?? 0) / 100) !== 4) {
      return null;
    }
    const bodyError = err.response?.data;

    if (err.response?.status === 401 && !bodyError) {
      navigateToError({
        Action: Action.InvalidTokenError,
        Data: null,
        Token: '',
        SessionToken: '',
        Error: {
          Code: ErrorCodes.InvalidTokenError,
          ErrorCode: ErrorCodes.InvalidTokenError,
        },
      });
      return true;
    }
    if (err.response?.status === 403 && !bodyError) {
      navigateToError({
        Action: Action.ForbidenTokenError,
        Data: null,
        Token: '',
        SessionToken: '',
        Error: {
          Code: ErrorCodes.ForbidenTokenError,
          ErrorCode: ErrorCodes.ForbidenTokenError,
        },
      });
      return true;
    }
    return err.response?.data ?? null;
  };
  const handle5xxError = (err: AxiosError<NOSIDError, any>) => {
    if (Math.floor((err.response?.status ?? 0) / 100) !== 5) {
      return null;
    }

    const bodyError: NOSIDError = err.response?.data
      ? { ...err.response?.data, IsInternalError: true }
      : {
          Code: ErrorCodes.UnknownError,
          ErrorCode: ErrorCodes.UnknownError,
          IsInternalError: true,
        };
    navigateToError({
      Action: Action.UnknownError,
      Data: null,
      Token: '',
      SessionToken: '',
      Error: bodyError,
    });
    return true;
  };

  return useMutation(
    async (input: TInput) => {
      if (options?.loader ?? true) {
        loader.showLoader();
      }
      return await mutationFn(axios, input);
    },
    {
      onSettled: () => {
        if (options?.loader ?? true) {
          loader.hideLoader();
        }
      },
      onSuccess: (res, input) => {
        if (res) {
          const keepSession =
            sessionStorageService.getString(KEEP_SESSION_STORAGE_KEY) ===
            'true';

          if (res.SessionToken) {
            if (keepSession) {
              localStorageService.setString(
                SESSION_TOKEN_STORAGE_KEY,
                res.SessionToken,
              );
            } else {
              sessionStorageService.setString(
                SESSION_TOKEN_STORAGE_KEY,
                res.SessionToken,
              );
            }
          }
        }
        options?.onResponse?.(res, input);
      },
      onError: (error: AxiosError<ResponseAction | NOSIDError>, input) => {
        let res = handle4xxError(error as AxiosError<ResponseAction, any>);

        if (!res) {
          res = handle5xxError(error as AxiosError<NOSIDError, any>);
        }

        if (typeof res === 'boolean') {
          return;
        }

        let response = error.response?.data;

        if (error.code === 'ERR_NETWORK') {
          response = {
            Error: {
              IsInternalError: true,
              Code: ErrorCodes.NetworkError,
              ErrorCode: ErrorCodes.NetworkError,
            },
          } as unknown as ResponseAction;
        }

        options?.onResponse?.(response! as ResponseAction, input);
      },
      retry: options?.retry,
    },
  );
}
